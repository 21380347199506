
import { defineComponent } from "vue";
// import Dropdown1 from '../../components/dropdown/Dropdown1.vue';
import { toolbarWidthFluid } from '../../core/helpers/config';

export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  components: {
    // Dropdown1,
  },
  setup() {
    return {
      toolbarWidthFluid,
    };
  },
});
