import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "page d-flex flex-row flex-column-fluid" }
const _hoisted_2 = {
  id: "kt_wrapper",
  class: "wrapper d-flex flex-column flex-row-fluid"
}
const _hoisted_3 = { class: "container1" }
const _hoisted_4 = {
  class: "box",
  style: {"width":"100%"}
}
const _hoisted_5 = {
  class: " box overlap content flex-row-fluid",
  style: {"width":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTLoader = _resolveComponent("KTLoader")!
  const _component_KTHeader = _resolveComponent("KTHeader")!
  const _component_KTToolbar = _resolveComponent("KTToolbar")!
  const _component_KTAside = _resolveComponent("KTAside")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_KTFooter = _resolveComponent("KTFooter")!
  const _component_KTScrollTop = _resolveComponent("KTScrollTop")!
  const _component_KTExplore = _resolveComponent("KTExplore")!
  const _component_KTDrawerMessenger = _resolveComponent("KTDrawerMessenger")!
  const _component_KTUserMenu = _resolveComponent("KTUserMenu")!
  const _component_KTCreateApp = _resolveComponent("KTCreateApp")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    (_ctx.loaderEnabled)
      ? (_openBlock(), _createBlock(_component_KTLoader, {
          key: 0,
          logo: _ctx.loaderLogo
        }, null, 8 /* PROPS */, ["logo"]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" begin:: Body "),
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_KTHeader, { title: _ctx.pageTitle }, null, 8 /* PROPS */, ["title"]),
        _createVNode("div", _hoisted_3, [
          _createCommentVNode(" begin:: Content Head "),
          _createVNode("div", _hoisted_4, [
            (_ctx.subheaderDisplay)
              ? (_openBlock(), _createBlock(_component_KTToolbar, {
                  key: 0,
                  breadcrumbs: _ctx.breadcrumbs,
                  title: _ctx.pageTitle
                }, null, 8 /* PROPS */, ["breadcrumbs", "title"]))
              : _createCommentVNode("v-if", true)
          ]),
          _createCommentVNode(" end:: Content Head "),
          _createCommentVNode(" begin:: Content "),
          _createVNode("div", {
            id: "kt_content",
            class: [" d-flex flex-column-fluid align-items-start", {
          'container-fluid': _ctx.contentWidthFluid,
          'container-xxl': !_ctx.contentWidthFluid,
        }]
          }, [
            _createCommentVNode(" begin:: Aside Left "),
            (_ctx.asideEnabled)
              ? (_openBlock(), _createBlock(_component_KTAside, {
                  key: 0,
                  lightLogo: _ctx.themeLightLogo,
                  darkLogo: _ctx.themeDarkLogo
                }, null, 8 /* PROPS */, ["lightLogo", "darkLogo"]))
              : _createCommentVNode("v-if", true),
            _createCommentVNode(" end:: Aside Left "),
            _createCommentVNode(" begin:: Content Body "),
            _createVNode("div", _hoisted_5, [
              _createVNode(_component_router_view)
            ]),
            _createCommentVNode(" end:: Content Body ")
          ], 2 /* CLASS */)
        ]),
        _createVNode(_component_KTFooter),
        _createCommentVNode(" end:: Content ")
      ])
    ]),
    _createCommentVNode(" end:: Body "),
    _createVNode(_component_KTScrollTop),
    _createVNode(_component_KTExplore),
    _createVNode(_component_KTDrawerMessenger),
    _createVNode(_component_KTUserMenu),
    _createVNode(_component_KTCreateApp)
  ], 64 /* STABLE_FRAGMENT */))
}