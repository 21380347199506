
import { defineComponent, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { MenuComponent } from "@/assets/ts/components";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import { headerMenuIcons } from "@/core/helpers/config";
import { mapState } from "vuex";
import Globals from '@/views/common/Globals.vue'
// import { role } from "src/helper";
// import { UserRoles, OrgRoles} from '../../helper/role'

export default defineComponent({
  name: "KTMenu",
  mixins:[Globals],
  components: {},
  computed: {
    ...mapState(["orgRole", "userRole"]),
  },
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const checkAccess = (path, orgRole, userRole) => {
      return false;
    };

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return {
      hasActiveChildren,
      headerMenuIcons,
      MainMenuConfig,
      translate,
      checkAccess,
    };
  },
});
