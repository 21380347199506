
import { defineComponent } from "vue";
import { footerWidthFluid } from "@/core/helpers/config";
import FAQ from "@/views/FAQ.vue";

export default defineComponent({
  name: "KTFooter",
  setup() {
    return {
      footerWidthFluid,
    };
  },
  component: {
    FAQ,
  },
});
