import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "kt_aside",
  class: "aside card",
  "data-kt-drawer": "true",
  "data-kt-drawer-name": "aside",
  "data-kt-drawer-activate": "{default: true, lg: false}",
  "data-kt-drawer-overlay": "true",
  "data-kt-drawer-width": "{default:'200px', '300px': '250px'}",
  "data-kt-drawer-direction": "start",
  "data-kt-drawer-toggle": "#kt_aside_mobile_toggle"
}
const _hoisted_2 = { class: "aside-menu flex-column-fluid" }
const _hoisted_3 = {
  class: "aside-footer flex-column-auto pt-5 pb-7 px-5",
  id: "kt_aside_footer"
}
const _hoisted_4 = {
  href: "https://preview.keenthemes.com/metronic8/vue/docs/#/doc-overview",
  class: "btn btn-custom btn-primary w-100",
  "data-bs-toggle": "tooltip",
  "data-bs-trigger": "hover",
  "data-bs-delay-show": "8000",
  title: "Check out the complete documentation with over 100 components"
}
const _hoisted_5 = { class: "btn-label" }
const _hoisted_6 = { class: "svg-icon btn-icon svg-icon-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTMenu = _resolveComponent("KTMenu")!
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createCommentVNode("begin::Aside"),
    _createVNode("div", _hoisted_1, [
      _createCommentVNode("begin::Aside menu"),
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_KTMenu)
      ]),
      _createCommentVNode("end::Aside menu"),
      _createCommentVNode("begin::Footer"),
      _createVNode("div", _hoisted_3, [
        _createVNode("a", _hoisted_4, [
          _createVNode("span", _hoisted_5, _toDisplayString(_ctx.t("docsAndComponents")), 1 /* TEXT */),
          _createVNode("span", _hoisted_6, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen005.svg" })
          ])
        ])
      ]),
      _createCommentVNode("end::Footer")
    ]),
    _createCommentVNode("end::Aside")
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}